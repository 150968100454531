import * as React from "react";
import "../i18n";
import "./style.css";
import Layout from "../components/Layout/Layout";
import Seo from "../components/shared/Seo";
import { useTranslation } from "react-i18next";
import BlogContent from "../templates/BlogContent";

export default function PrivacyPolicyPage() {
  const { t } = useTranslation();
  return (
    <Layout>
      <Seo title="Privacy policy" description="View our privacy policy here. Read about cookies, gdpr and more." canonicalPath="/privacy-policy"/>
      <BlogContent blogpost={t("privacy_policy_page.content")} />
    </Layout>
  );
}
